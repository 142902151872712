import { ReactNode } from 'react';
import Link from 'next/link';

type Props = {
  children: ReactNode;
  href: string;
  target?: string;
  useLink: boolean;
  onClick: () => void;
  marginTop: number;
};

const className =
  'border-b-[1px] border-blue font-cera text-blue font-bold text-[16px] leading-[20px] tracking-[-0.22px]';

const UnderlinedLink = ({
  children,
  href,
  target,
  useLink,
  onClick,
  marginTop,
}: Props): JSX.Element =>
  useLink ? (
    <Link
      href={href}
      onClick={onClick}
      className={className}
      style={{ marginTop: `${marginTop}px` }}
    >
      {children}
    </Link>
  ) : (
    <a
      href={href}
      target={target}
      className={className}
      onClick={onClick}
      style={{ marginTop: `${marginTop}px` }}
    >
      {children}
    </a>
  );

UnderlinedLink.defaultProps = {
  useLink: false,
  onClick: () => undefined,
  marginTop: 40,
};

export default UnderlinedLink;
